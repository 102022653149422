const _boneHealthCalc = {
  age: {
    title: `Age`,
    blurb: `While advanced age is the most significant risk factor for osteoporosis, bone density issues, and osteoporotic fractures are possible at any age. Proper diet and physical activity are essential to reach peak bone mass by age 25. Your risk of osteoporosis increases with age. Throughout our lives, our bones go through a process called remodeling, where bone cells are both newly formed and resorbed. Bone mass begins to decline around age 30 at about 1% per year.
    <br/><br/>
During and after menopause, women often experience a rapid loss of bone density due to changes in hormones. Men lose bone density at a slower pace until around the age of 65 when both genders lose bone density at the same rate. Programs like OsteoStrong offer osteogenic loading programs that help prevent and mitigate age-related bone loss while also improving balance and energy.
    <br/><br/>
Demontiero, O., Vidal, C., & Duque, G. (2012). Aging and bone loss: new insights for the clinician. <i>Therapeutic advances in musculoskeletal disease</i>, 4(2), 61–76:  <a href="https://journals.sagepub.com/doi/10.1177/1759720X11430858" target="_blank" rel="noopener noreferrer">https://doi.org/10.1177/1759720X11430858</a>
    `,
  },

  appetite: {
    title: `Appetite, Cravings, and Nutrition`,
    blurb: `Since bones are directly involved in the upkeep of the circulatory system: it makes sense that skeletal conditioning impacts the mechanisms that encourage us to eat and eat well.  A poor framework encourages a more sedentary lifestyle - that's a vicious downward spiral in the making!  OsteoStrong is an activity that has huge benefits, not the least of which is an incredible benefit from very short session times.
    `,
  },

  ethnicity: {
    title: `Ethnicity`,
    blurb: `Ethnicity can play a role in determining bone size, density, structure, and the body's ability to regulate calcium. Researchers have found that individuals of African descent have stronger bones and less risk of early fracture. However, Asian and Caucasian women have a higher risk of developing osteoporosis than other ethnicities. However, it is very important to note that ethnicity alone won't determine whether you develop osteoporosis. Body type, physical activity, diet, and other lifestyle factors greatly influence bone loss.
    <br/><br/>
Aloia J. F. (2008). African Americans, 25-hydroxyvitamin D, and osteoporosis: a paradox. <i>The American journal of clinical nutrition</i>, 88(2), 545S–550S:  <a href="https://academic.oup.com/ajcn/article/88/2/545S/4650027" target="_blank" rel="noopener noreferrer">https://doi.org/10.1093/ajcn/88.2.545a</a>
    `,
  },

  sleepy: {
    title: `Sleep Apnea and Sleeplessness`,
    blurb: `Osteoporosis generally occurs without any symptoms, but there is growing evidence indicating a connection that certain disorders such as insomnia and chronic sleep apnea share significant pathogenesis with low bone density. Research suggests a significant link between lack of sleep and bone health for both women and men.
    <br/><br/>
Yi, S. S., Chung, S. H., & Kim, P. S. (2018). Sharing Pathological Mechanisms of Insomnia and Osteoporosis, and a New Perspective on Safe Drug Choice. Journal of menopausal medicine, 24(3), 143–149:  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6336562/" target="_blank" rel="noopener noreferrer">https://doi.org/10.6118/jmm.2018.24.3.143</a>
    <br/><br/>
Bone mineral density and changes in bone metabolism in patients with obstructive sleep apnea syndrome. <i>Journal of Bone and Mineral Metabolism</i> 2015 [In press DOI: 10.1007/s00774-015-0691-1]:  <a href="https://www.ncbi.nlm.nih.gov/pubmed/22467193?dopt=Abstract" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/22467193?dopt=Abstract</a>
    `,
  },

  medication: {
    title: `Medication`,
    blurb: `Medication-induced osteopenia and osteoporosis is a serious issue. Glucocorticoids, proton pump inhibitors, SSRI's, anticonvulsants, aromatase inhibitors, heparin, some chemotherapies, and many hormone-related medications can have adverse effects on your bone health. Please speak to your doctor about your medication and whether it puts you at risk of osteoporosis.
    <br/><br/>
Panday, K., Gona, A., & Humphrey, M. B. (2014). Medication-induced osteoporosis: screening and treatment strategies. <i>Therapeutic advances in musculoskeletal disease</i>, 6(5), 185–202:  <a href="https://doi.org/10.1177/1759720X14546350" target="_blank" rel="noopener noreferrer">https://doi.org/10.1177/1759720X14546350</a>
    `,
  },

  strength: {
    title: `Conditioning`,
    blurb: `It makes sense that your strength is directly related to your bones because they are your body's framework.  You may not realize, however, that your body limits your maximum strength output based on your bone density.  Skeletal force application, which OsteoStrong pioneered, is the ultimate way to increase bone strength.
    `,
  },

  treatment: {
    title: `Bone-Mass Density (BMD Test)`,
    blurb: `The most common way to determine your bone density and fracture risk is to have a DXA scan or central dual-energy x-ray absorptiometry. This simple, painless test can measure your bone density at your hip and spine. The results will help your practitioner diagnose osteoporosis, predict the risk of fracture, and see if your BMD is improving or getting worse.  
	<br/>
For more info:  <a href="https://www.bones.nih.gov/health-info/bone/bone-health/bone-mass-measure" target="_blank" rel="noopener noreferrer">https://www.bones.nih.gov/health-info/bone/bone-health/bone-mass-measure</a>
    <br/><br/>
<h3>hipTScore</h3>
	<br/>
After you have a DXA scan, the doctor will compare your results to those of an average healthy young person, then you will be given a T-Score. A healthy bone score is between +1 and -1, -1 and -2.5 indicate low bone mass or osteopenia, and -2.5 or lower is an indicator of osteoporosis. A higher negative number suggests that your osteoporosis is more advanced.
	<br/>
For more info:  <a href="https://www.nof.org/patients/diagnosis-information/bone-density-examtesting/" target="_blank" rel="noopener noreferrer">https://www.nof.org/patients/diagnosis-information/bone-density-examtesting/</a>
    `,
  },

  alcohol: {
    title: `Alcohol`,
    blurb: `High alcohol consumption can harm your bone health and contribute to developing osteoporosis. Research shows that alcohol affects your body's calcium balance, increases cortisol, and can even cause hormone deficiencies in men and women. All of these factors are associated with decreased bone formation. Alcohol is also connected to a higher risk of fracture and falls because it can seriously throw off your balance. Heavy alcohol use is also associated with the most serious osteoporotic injury of all - hip fracture. Incorporating healthy lifestyle habits and physical activity, including impact emulation and osteogenic loading, can prevent and reverse the effects of alcohol on the body.
    <br/><br/>
Sampson H. W. (1998). Alcohol's harmful effects on bone. <i>Alcohol health and research world</i>, 22(3), 190–194:  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6761900/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6761900/</a>
    `,
  },

  diet: {
    title: `Diet & Nutrition`,
    blurb: `Your diet can have a profound effect on your overall health and your bone density. A diet rich in calcium and vitamin D and other minerals and nutrients helps in the development of peak bone mass and the retention of bone density as we age. Diet-related health conditions such as celiac disease affect bone density because they may cause calcium malabsorption. Individuals with eating disorder issues, low body weight, and cessation of menses will often experience low estrogen levels, which puts them at risk of having low bone density and a higher risk of fracture. Skeletal strength conditioning can help improve strength, energy, balance, and bone health to remediate the long-term risks of low bone density and osteoporosis. 
    <br/><br/>
Misra, M., & Klibanski, A. (2014). Anorexia nervosa and bone. <i>Journal of Endocrinology</i>, 221(3). doi: 10.1530/joe-14-0039:  <a href="https://www.bones.nih.gov/health-info/bone/osteoporosis/conditions-behaviors/anorexia-nervosa" target="_blank" rel="noopener noreferrer">https://www.bones.nih.gov/health-info/bone/osteoporosis/conditions-behaviors/anorexia-nervosa</a>
	<br/>
Scott EM, Gaywood I,Scott BB Guidelines for osteoporosis in coeliac disease and inflammatory bowel disease <i>Gut</i> 2000;46:I1-I8:  <a href="http://dx.doi.org/10.1136/gut.46.suppl_1.I1" target="_blank" rel="noopener noreferrer">http://dx.doi.org/10.1136/gut.46.suppl_1.I1</a>
    `,
  },

  period: {
    title: `Menstrual Cycle`,
    blurb: `Women who experience amenorrhea or cessation of their menstrual cycle have hormone fluctuations that can negatively affect bone density. It is widely known that women experience a loss of bone density at the time of menopause. However, few recognize young women whose menstrual cycle stops or is affected by disordered eating, low body weight, or high-intensity athleticism face low bone density issues and a higher risk of a stress fracture. Regardless of age or the cause of low bone density, engaging in weight-bearing and mechanical loading programs like OsteoStrong can offer significant benefits for improving overall health and skeletal strength.
    <br/><br/>
    <a href="https://www.natajournals.org/doi/full/10.4085/1062-6050-45.3.243" target="_blank" rel="noopener noreferrer">Mitchell J. Rauh, Jeanne F. Nichols, and Michelle T. Barrack (2010) Relationships Among Injury and Disordered Eating, Menstrual Dysfunction, and Low Bone Mineral Density in High School Athletes: A Prospective Study. Journal of Athletic Training: May/Jun 2 </a>2010, Vol. 45, No. 3, pp. 243-252.
    `,
  },

  cancer: {
    title: `Cancer treatment and Bone Density`,
    blurb: `Chemotherapy and other cancer treatments can have profound effects on skeletal health in both women and men. Women who experience ovarian failure face a rapid decline in bone density resulting in a higher risk of fracture due the sudden decrease in estrogen. Hormones also play a role in increasing the risk of osteoporosis for men as well. Prostate cancer treatments for men are known to affect testosterone and estrogen levels resulting in a higher turnover of bone cells and decreased bone density. OsteoStrong can be a valuable resource during and after cancer treatment to help patients regain strength, increase skeletal conditioning, and reduce the risk of decreasing bone density.
    <br/><br/>
<a href="https://ascopubs.org/doi/abs/10.1200/JCO.2001.19.14.3306" target="_blank" rel="noopener noreferrer">Ovarian Failure After Adjuvant Chemotherapy Is Associated With Rapid Bone Loss in Women With Early-Stage Breast Cancer</a>
<br/>
Charles L. Shapiro, Judith Manola, and Meryl Leboff
<br/>
Journal of DOI: 10.1200/JCO.2001.19.14.3306 <i>Journal of Clinical Oncology</i> 19, no. 14 (July 15, 2001) 3306-3311. Clinical Oncology 2001 19:14, 3306-3311
    <br/><br/>
Via, J. D., Daly, R. M., Owen, P. J., Mundell, N. L., Rantalainen, T., & Fraser, S. F. (2019). 
<br/>
Bone mineral density, structure, distribution and strength in men with prostate cancer treated with androgen deprivation therapy. Bone, 127, 367–375. doi: 10.1016/j.bone.2019.06.005
   `,
  },

  smoke: {
    title: `Smoking`,
    blurb: `Most people know that smoking is bad for your lungs, but many do not recognize how detrimental it is to bone health. The longer you smoke and the more cigarettes you inhale, the higher your risk of fracture in old age. Smoking is also associated with slower healing and more complications in the event of a fracture. Additionally, smoking lessens blood flow to the bones, slows the production of bone-forming cells, and decreases calcium absorption. According to researchers, one in eight hip fractures may be associated with smoking.
    <br/><br/>
    Law MR, Hackshaw AK. A meta-analysis of cigarette smoking, bone mineral density and risk of hip fracture: recognition of a major effect. BMJ 1997; 315:841-6.
	<br/><br/>
	<a href="https://www.iofbonehealth.org/news/smoking-real-danger-your-bone-health" target="_blank" rel="noopener noreferrer">Smoking is a Real Danger to Your Bone Health</a>
	<br/><br/>
	<a href="https://www.hindawi.com/journals/jos/2018/1206235/" target="_blank" rel="noopener noreferrer">Al-Bashaireh, A. M., Haddad, L. G., Weaver, M., Chengguo, X., Kelly, D. L., & Yoon, S. (2018). The Effect of Tobacco Smoking on Bone Mass: An Overview of Pathophysiologic Mechanisms. <i>Journal of Osteoporosis</i>, 2018, 1–17. doi: 10.1155/2018/1206235</a>
    `,
  },

  parentFracture: {
    title: `Parental Fracture`,
    blurb: `Your risk of osteoporotic fracture increases if one of your parents has experienced a fracture, particularly if it occurred before the age of 70.
    <br/><br/>
	<a href="https://doi.org/10.1002/jbmr.2849" target="_blank" rel="noopener noreferrer">Yang, S., Leslie, W.D., Yan, L., Walld, R., Roos, L.L., Morin, S.N., Majumdar, S.R. and Lix, L.M. (2016), Objectively Verified Parental Hip Fracture Is an Independent Risk Factor for Fracture: a Linkage Analysis of 478,792 Parents and 261,705 Offspring. J Bone Miner Res, 31: 1753-1759. doi:10.1002/jbmr.2849</a>
    <br/><br/>
	<a href="https://doi.org/10.1002/jbmr.3035" target="_blank" rel="noopener noreferrer">Yang, S., Leslie, W.D., Walld, R., Roos, L.L., Morin, S.N., Majumdar, S.R. and Lix, L.M. (2017), Objectively‐Verified Parental Non‐Hip Major Osteoporotic Fractures and Offspring Osteoporotic Fracture Risk: A Population‐Based Familial Linkage Study. J Bone Miner Res, 32: 716-721. doi:10.1002/jbmr.3035</a>
    `,
  },

  fracture: {
    title: `Fracture`,
    blurb: `When a person experiences a bone fracture, it may be the first indication that they may have a low bone density issue like osteoporosis. Osteoporosis weakens the bone structure making it more porous and susceptible to breaking or fracture. The good news is that you can take steps like incorporating OsteoStrong into your recovery to protect your physical freedom by strengthening your skeletal system, improving your balance, and making your body more resilient.
	<br/><br/>
	Once Is Enough: A Guide to Preventing Future Fractures. (n.d.). Retrieved from <a href="https://www.bones.nih.gov/health-info/bone/osteoporosis/fracture" target="_blank" rel="noopener noreferrer">https://www.bones.nih.gov/health-info/bone/osteoporosis/fracture</a>
    `,
  },

  arthritis: {
    title: `Arthritis & Inflammation`,
    blurb: `Not only does joint pain (and pain in general) lead us to be less active; some medications used in the treatment of arthritis and other inflammation can trigger significant bone loss.  Not only can OsteoStrong help mitigate this loss but we can often help reverse it's causes.}
    <br/><br/>
    Rheumatoid arthritis and osteoporosis: <a href="https://www.bones.nih.gov/health-info/bone/osteoporosis/conditions-behaviors/osteoporosis-ra">https://www.bones.nih.gov/health-info/bone/osteoporosis/conditions-behaviors/osteoporosis-ra</a>
    `,
  },
}

export const boneHealthCalc = _boneHealthCalc