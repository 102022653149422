import './App.css'
import React,{useState} from 'react';
import BoneHealthCalc from './boneHealthCalc'
import Logo from './img/logo192.png'
import querystring from 'querystring'
import {titleCase} from './lib/helpers'

function App() {
  const Parm = querystring.parse(window.location.search.slice(1))
  const PathName = window.location.pathname.split('/')[1] || ''
  // const Parm = JSON.parse(window.location.search.slice(5))

  const [path, setPath] = useState(PathName)

  console.log('Parm', window.location.search)
  console.log('Path', path)

  function MainContent() {
    if (Parm.webform) {
      if (Parm.webform === 'BONEHEALTH0') {
        return <BoneHealthCalc params={Parm} />
      } else {
        return (
          <ul>
            <li>
              <a href="/?webform=BONEHEALTH0">Bone-Health Calculator</a>
            </li>
          </ul>
        )
      }
    } else {
      return <></>
    }
  }

  return (
    <div className="App">
      <header>
        <img className="app logo" src={Logo} />
      </header>

      <MainContent />
    </div>
  );
}

export default App;
