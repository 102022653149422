import React, { Component } from 'react'
import CanvasJSReact from '../lib/canvasjs.react'

var CanvasJS = CanvasJSReact.CanvasJS
var CanvasJSChart = CanvasJSReact.CanvasJSChart

export default function RiskChart({age=50,risk=5}) {
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    title:{
      text: "Bone-Health Can Be Had!",
      fontColor: 'black',
    },
    subtitles: [{
      text: 'OsteoStrong is the pioneer of non-pharmaceutical skeletal strength conditioning',
      fontColor: 'black'
    }],
    axisY: {
      title: "% Risk of Fracture",
      includeZero: true,
      maximum: 45,
      suffix: "%",
      stripLines: [{
        startValue: 0,
        endValue: 10,
        color: '#0F0',
      },{
        startValue: 10,
        endValue: 20,
        color: '#FF0',
      },{
        startValue: 20,
        endValue: 45,
        color: '#F00',
      }],
    },
    axisX: {
      title: "Age in Years",
      interval: 2,
      
    },
    data: [{
      lineColor: '#333A',
      lineDashType: "longDash",
      lineThickness: 5,
      markerType: 'none',
      type: "line",
      toolTipContent: "{y}% at {x}yrs",
      dataPoints: [
        { x: 45, y: 2 },
        { x: 48, y: 2 },
        { x: 50, y: 2.5 },
        { x: 55, y: 5 },
        { x: 60, y: 7.5 },
        { x: 65, y: 10.1 },
        { x: 70, y: 12.6 },
        { x: 75, y: 16 },
        { x: 80, y: 22 },
        { x: 85, y: 34 },
      ]
    },{
      type: "scatter",
      toolTipContent: "Age {x}, Risk {y}%",
      markerType: 'triangle',
      markerColor: 'blue',
      markerSize: 13,
      dataPoints: [
        { x: Math.round(age), y: Math.round(risk) },
      ]
    }]
  }

  console.log(age,risk)

  return (
    <CanvasJSChart options={options} />
  )
}