import React,{useState} from 'react';
import Chart from './RiskChart'
import {boneHealthCalc as Content} from './Content'
import {titleCase} from '../lib/helpers'

import './index.css'

function App({params}) {
  function ContentPiece({item}) {
    let content = Content[item]
    let key = item.toLowerCase()
    let subTitle = ''
    let title = ''
    let val = params[item]
    let verbage = ''
    
    if (Array.isArray(val)) {
      // meaning the param appears more than once in the query-string
      val = val[0]
    }
    
    if (!content || (val === 'no') || !val) {
      return <></>
    } else if (val === 'yes') {
      title = `${content.title}`
      subTitle = ''
      verbage = content.blurb
    } else {
      val = val.toLowerCase().trim() || val
      console.log('entry', key, val, content)
      
      if (!content.title && !content.blurb) {
        // then the content is a sub-object
        try {          
          content = content[val] ? content[val] : content.default
        } catch (error) {
          // dunno what the content is...but messed up
          content = {
            title: 'Nonmisnomer',
            blurb: 'Extraneous unquantified semantic pedestrian rousings.',
          }          
        }
      }
      
      console.log('exit', content)

      if (val === 'default') {
        subTitle = ''
      } else {
        if (Array.isArray(val)) val = val[0]  // if multiple queries with same-name
        subTitle = `, ${titleCase(val)}`
      }

      title = `${content.title}`
      verbage = content.blurb
    }
    
    
    return (<div className="content">
    <h3>{title}{subTitle}</h3>
    <p 
    className="blurb"
    dangerouslySetInnerHTML={{__html: verbage}}
    ></p>
    </div>)
  }
  
  return (
    <div className="bone health calc">
    <Chart 
    age={params.age}
    risk={params.risk}
    />
    
    <div className="container">
    {Object.keys(params).map(el=><ContentPiece key={el} item={el} />)}
    </div>
    </div>
    )
  }
  
  export default App